"use client";

// Library
import { Link } from "@/lib/i18n";
import * as m from "@/paraglide/messages";

// Components
import { motion } from "framer-motion";
import Button from "../../Button/Button";
import Image from "next/image";

export default function EndCTA(props) {
    if (!props.join && !props.mentorat && !props.apprenticeship) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-7xl sm:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_default_title()}
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_default_description()}
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="/signup">
                            <Button type="pop">
                                {m.EndCTA_default_button()}
                            </Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.join) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_join_movement_title()}
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_join_movement_description_1()}
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_join_movement_description_2()}
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="mailto:hello@believemy.com">
                            <Button>{m.EndCTA_join_movement_button()}</Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.mentorat) {
        return (
            <>
                <section className="mt-52 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_mentoring_title()}
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_mentoring_description_1()}
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_mentoring_description_2()}
                    </motion.p>
                    <motion.div
                        className="flex justify-center mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="mailto:hello@believemy.com">
                            <Button>{m.EndCTA_mentoring_button()}</Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
    if (props.apprenticeship) {
        return (
            <>
                <section className="mt-32 container dark:text-white text-center">
                    <motion.h2
                        className="font-bold md:text-6xl text-5xl xl:w-8/12 w-11/12 mx-auto"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_apprenticeship_title()}
                    </motion.h2>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_apprenticeship_description_1()}
                    </motion.p>
                    <motion.p
                        className="mt-10 xl:w-7/12 w-10/12 mx-auto text-xl"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {m.EndCTA_apprenticeship_description_2()}
                    </motion.p>
                    <motion.div
                        className="flex justify-center gap-5 mt-10"
                        initial={{ opacity: 0, y: 15 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        <Link href="/program/le-programme-rocket/admission">
                            <Button type="confidence">
                                {m.EndCTA_apprenticeship_button_1()}
                            </Button>
                        </Link>
                        <Link href="mailto:hello@believemy.com">
                            <Button type="light">
                                {m.EndCTA_apprenticeship_button_2()}
                            </Button>
                        </Link>
                    </motion.div>
                    <div className="mt-32 flex justify-center">
                        <Image
                            src="/images/site/assets/glob.svg"
                            alt="Believemy is with anyone"
                            width={1167}
                            height={500}
                            className="object-contain"
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                        />
                    </div>
                </section>
            </>
        );
    }
}
